<template>
	<SubPageLayout>
		<StackedPanels>
			<PanelSection>
				<h2 class="text-lg leading-6 font-medium text-gray-900">Interaction settings</h2>

				<FormKit id="updateInteractionSettings" v-model="builderStore.settings" :preserve="true" type="form" :actions="false" class="flex w-full">
					<div class="grid grid-cols-12 gap-x-2 gap-y-2 sm:gap-y-4 my-4">
						<FormKitSchema :schema="interactionSettings" />
					</div>
				</FormKit>
				<template #footer>
					<div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
						<button
							class="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 cursor-pointer"
							type="button"
							@click="saveForm"
						>
							Save
						</button>
					</div>
				</template>
			</PanelSection>
		</StackedPanels>
	</SubPageLayout>
</template>

<script setup lang="ts">
	import StackedPanels from '@components/StackedPanels.vue';
	import PanelSection from '@components/PanelSection.vue';
	import SubPageLayout from '@layouts/SubPageLayout.vue';
	import { useBuilderStore } from '@modules/builder/store';
	import { getNode, reset } from '@formkit/core';
	import { onBeforeUnmount } from 'vue';

	const builderStore = useBuilderStore();

	const saveForm = () => {
		if (getNode('updateInteractionSettings')?.context?.state.valid) {
			builderStore.updateTemplate();
		}
	};

	const interactionSettings = [
		{
			$formkit: 'text',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-6': true, 'xl:col-span-6': true },
			validation: [['required'], ['length:0,255']],
			id: 'interaction_label',
			name: 'interaction_label',
			label: 'Interaction label',
			placeholder: 'Enter the the way this template will refer to interactions',
		},
	];

	onBeforeUnmount(async () => {
		reset('updateInteractionSettings');
	});
</script>
